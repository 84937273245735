body {
  margin: 0;
  background-color: #91f2ff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  color: #ffb091;
  font-family: "Great Vibes", cursive;
  font-weight: 500;
  font-style: normal;
  margin-bottom: 10px;
}

p {
  margin-top: 0
}


@media(min-width: 450px) {
  h1 {
    font-size: 5em;
  }
  p {
    font-size: 2em;
  }
}