.cardContainer {
    width: 1080px;
    height: 1080px;
    background-color: #91f2ff;
}

.heading {
    fill: #ffb091;
    font-size: 5em;
    font-family: "Great Vibes", cursive;
    font-weight: 500;
    font-style: normal;
}

.complementary {
    fill: #ffe791;
}

.info {
    color: black;
    font-size: 2em;
    text-align: center;
}