.contentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.primaryCursive {
    color: #ffb091;
    font-family: "Great Vibes", cursive;
    font-weight: 500;
    font-style: normal;
}

.complementary {
    font-family: "Great Vibes", cursive;
    font-weight: 500;
    font-style: normal;
}
