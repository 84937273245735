.rsvpContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.columnsContainer {
    width: 80vw;
    display: flex;
    justify-content: space-around;
}

@media (max-width: 450px) {
    .columnsContainer {
        flex-direction: column-reverse;
    }
}

.usersContainer {
    border: 1px solid black;
    padding: 1px 3px;
    display: flex;
    flex-direction: column;
}

.guestList {
    border-top: 3px solid black;
    padding: 3px 5px;
    list-style: none;
}

.editContainer {
    border: 1px solid black
}

.center {
    text-align: center;
}

.legend {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.legendLabels {
    display: flex;
    flex-wrap: wrap;
}

.legend span {
    padding: 3px;
}

.attending {
    background-color: #64d564;
}

.notAttending {
    background-color: #ef8d8d;
}

.noResponse {
    background-color: #c5c599;
}

span.attending, span.notAttending, span.noResponse {
    font-weight: bold;
}

button.attending, button.notAttending, button.noResponse {
    font-size: 1.2em;
    margin: 5px 3px;
}

.rsvpButton {
    width: 80vw;
    padding: 5px;
    margin-top: 25px;
    background-color: #cf91ff;
}